// Generated code based on TsInclude/TsExclude Attributes on .cs classes
// DO NOT CHANGE MANUALLY!

export enum Tag {
  Unspecified = 0,
  Favourite = 1,
  Hidden = 2,
}
export function tagAware(constructor: Function) {
  constructor.prototype.Tag = Tag;
}
