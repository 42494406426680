import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  @Output() dataTableButtonMouseOverEvent = new EventEmitter<any>();
  @Output() dataTableButtonMouseOutEvent = new EventEmitter<any>();
  @Output() dataTableNeedsUpdate = new EventEmitter<any>();
  @Output() rowDeletedEvent = new EventEmitter<any>();
  @Output() draftDeletedEvent = new EventEmitter<any>();
  @Output() jobRequestTaggedEvent = new EventEmitter<any>();
  @Output() countsUpdatedEvent = new EventEmitter<any>();
  @Output() proposalWithdrawnEvent = new EventEmitter<any>();
  @Output() proposalRegistrationFailedEvent = new EventEmitter<any>();
  @Output() proposalCreatedEvent = new EventEmitter<any>();
  @Output() secondeeProposedEvent = new EventEmitter<any>();
  @Output() secondeeRemovedEvent = new EventEmitter<any>();
  @Output() secondeeEditEvent = new EventEmitter<any>();
  @Output() draftCreatedEvent = new EventEmitter<any>();
  @Output() screeningUpdatedEvent = new EventEmitter<any>();
  @Output() newPicklistItemCreatedEvent = new EventEmitter<any>();
  @Output() picklistItemUpdatedEvent = new EventEmitter<any>();
  @Output() showGlobalLoaderEvent = new EventEmitter<boolean>();
  @Output() previousNavigatedRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');
  @Output() screeningDelegatedEvent = new EventEmitter<any>();
  @Output() bigNumberRegisteredEvent = new EventEmitter<any>();
  @Output() delegatedIntegrityCheckStartedEvent = new EventEmitter<any>();
  @Output() documentLinkedToScreeningItemEvent = new EventEmitter<any>();
  @Output() formerEmployersRegisteredEvent = new EventEmitter<any>();
  @Output() identityVerificationStartedEvent = new EventEmitter<any>();
  @Output() integrityCheckStartedEvent = new EventEmitter<any>();
  @Output() professionalMutationSavedEvent = new EventEmitter<any>();
  @Output() languageChangedEvent = new EventEmitter<any>();
  @Output() dropdownSelectedEvent = new EventEmitter<any>();
  @Output() clickedExpenseDelete = new EventEmitter<any>();
  @Output() clickedExpenseRevert = new EventEmitter<any>();
  @Output() introCompleted = new EventEmitter<any>();
  @Output() clickedExpenseSave = new EventEmitter<any>();
  @Output() clickedExpenseSubmit = new EventEmitter<any>();
  @Output() clickedHoursExpensesDownload = new EventEmitter<any>();
  @Output() privacyStatement: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}
}
